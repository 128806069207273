import { attachStates } from "utils/ReduxUtils";
import appState from "state/App";
import React from "react";
import PropTypes from "prop-types";
import styles from "../Register/Register.module.scss";
import { api, getTestLabel } from "lib";
import { PageFrame } from "components";
import { RegisterForm } from "@dataplan/react-components/dist/components/ui/register_form";

class RegisterPage extends React.Component {

    static propTypes = {
        history: PropTypes.object.isRequired,
    };

    /**
     * Creates an instance of the register page
     *
     * @param {object} props Input props
     */
    constructor (props) {
        super(props);

        this.state = {
            stage: 'default',
            loading: false,
            complete: false,
            formData: {},
            error: null,
            errors: {},
        };

        this.handleRegisterAttempt = this.handleRegisterAttempt.bind(this);
    }

    /**
     * Handles the registration attempt
     *
     * @param {object} data The form data
     */
    handleRegisterAttempt (data) {
        this.setState((prevState) => ({
            ...prevState,
            loading: true,
            error: null,
            errors: {},
        }));

        const formData = {
            ...this.state.formData,
            ...data,
        };

        api.post("/auth/employee/register", data)
            .then(({ data: { next_stage: nextStage, employee, registered = false } }) => {
                this.setState((prevState) => ({
                    ...prevState,
                    loading: false,
                    employee,
                    formData: !registered ? formData : {},
                    stage: nextStage,
                    registered,
                }));
            }).catch(({response: {data: {message, messages}}}) => this.setState((prevState) => ({
                ...prevState,
                error: (message ?? "Failed to register"),
                errors: messages,
                loading: false,
            })));
    }

    /**
     * Handles redirecting the login page on completion
     *
     * @return {object} The history
     */
    handleRegisteredComplete = () => this.props.history.push('/login');

    /**
     * Handles redirecting the login page on cancel
     *
     * @return {object} The history
     */
    handleCancel = () => this.props.history.push('/login');

    /**
     * Renders the Register page content
     *
     * @return {ReactElement} The page container
     */
    render () {
        const isTipology = window.ENV.PLATFORM === "tipology";

        return (
            <div className={styles.container}>
                <div className={styles.registerForm} data-cy={getTestLabel('registerForm')}>
                    <PageFrame>
                        <RegisterForm
                            onRegisterCompleted={this.handleRegisteredComplete}
                            onRegisterAttempt={this.handleRegisterAttempt}
                            onCancel={this.handleCancel}
                            registered={this.state.registered}
                            stage={this.state.stage}
                            employee={this.state.employee}
                            loading={this.state.loading}
                            error={this.state.error}
                            errors={this.state.errors}
                            isTipology={isTipology}
                        />
                    </PageFrame>
                </div>
            </div>
        );
    }

}

export default attachStates([appState], RegisterPage);
