import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import api from '../lib/api';

import { ErrorFrame } from "components/";
import { HomeButton, LogoutButton } from '../pages/Errors/assets';

import styles from './ErrorBoundary.module.scss';

class ErrorBoundary extends React.Component {

    static propTypes = {
        children: PropTypes.object.isRequired,
        location: ReactRouterPropTypes.location.isRequired,
    };

    /**
     * Creates an instance of the error boundary
     *
     * @param {object} props The properties of the boundary
     */
    constructor (props) {
        super(props);

        this.state = {
            error: null,
        };
    }

    /**
     * Called when the component is addd to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        this.requestInterceptor = api.interceptors.request.use((request) => {
            this.setState({ error: null });

            return request;
        });

        this.responseInterceptor = api.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error?.response?.status >= 500 && error?.response?.status < 600) {
                    this.setState({ error });
                }

                return Promise.reject(error);
            },
        );
    }

    /**
     * Called when the component catches an error
     *
     * @param {*} error The error caught
     * @param {object} errorInfo Detailed info of the error stack
     *
     * @return {void}
     */
    componentDidCatch (error, errorInfo) {
        this.setState({
            error,
        });

        // Raven.captureException to be added here when Sentry added to app
    }

    /**
     * Called when the component is being removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount () {
        api.interceptors.request.eject(this.requestInterceptor);
        api.interceptors.response.eject(this.responseInterceptor);
    }

    /**
     * Callback passed to the error page to reset the state of the error boundary when using nav buttons
     *
     * @return {void}
     */
    resetErrorState = () => {
        this.setState({
            error: null,
        });
    };

    /**
     * Renders the buttons to be shown on the error frame
     *
     * @return {ReactElement} The button(s)
     */
    renderButtons = () => {
        const { pathname } = this.props.location;

        return (pathname !== "/" && pathname !== "/home")
            ? <HomeButton onClick={this.resetErrorState} />
            : <LogoutButton onClick={this.resetErrorState} />;
    };

    /**
     * Renders the Error Boundary, with the application inside
     *
     * @return {ReactElement} The application wrapped in the error boundary or an "Opps" page
     */
    render () {
        if (this.state.error) {
            return (
                <div className={styles.errorFrameWrapper}>
                    <ErrorFrame
                        title="Sorry, something went wrong"
                        content="Please use the buttons below to resume"
                        buttons={this.renderButtons()}
                    />
                </div>
            );
        } else {
            return this.props.children;
        }
    }

}

export default withRouter(ErrorBoundary);
