import React from "react";
import PageFrame from "components/PageFrame";
import DocumentsFrame from "components/DocumentsFrame";
import appState from "state/App";
import PropTypes from "prop-types";
import naturalCompare from 'string-natural-compare';
import _ from 'lodash';

import SearchBox from "@dataplan/react-components/dist/components/forms/SearchBox";
import { FilterTab } from '@dataplan/react-components/dist/components/ui/tabs';
import { AnimationContainer } from "@dataplan/react-components/dist/components/ui/animation";
import { PageHeader } from "components/";
import { getAppName } from "lib/";

import styles from './Documents.module.scss';

class Documents extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
    };

    /**
     * Creates an instance of the documents page
     *
     * @param {object} props Documents page properties
     */
    constructor (props) {
        super(props);
        this.pageName = "Documents";

        this.state = {
            tab: 0,
            shouldAnimate: true,
            documents: this.formatAvailableTypes(),
            value: "",
        };
    }

    /**
     * Called when the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        appState.setPageName(this.pageName);
        document.title = this.pageName;
    }

    /**
     * Called when the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount () {
        document.title = getAppName();
    }

    /**
     * Sets the state for the docs to be filtered
     *
     * @param {array} newDocuments the new docs
     * @param {string} searchValue the search value for the filter
     */
    setFilteredDocuments (newDocuments, searchValue) {
        this.setState({
            documents: newDocuments,
            shouldAnimate: false,
            value: searchValue,
        });
    }

    /**
     * Creates an array of document types that have documents available
     *
     * @return {array} The array of document types with documents
     */
    formatAvailableTypes = () => {
        const {
            personal,
            personalcompany,
            company,
            "drawing_board_documents": documents,
        } = this.props.appState.documents;


        const personalAndPersonalCompany = [...personal, ...personalcompany, ...documents];
        const tabsRequired = Boolean(personalAndPersonalCompany.length && company.length);

        const typeMap = [
            {
                text: "All",
                collection: [...personalAndPersonalCompany, ...company],
            },
            {
                text: "Personal",
                collection: personalAndPersonalCompany,
            },
            {
                text: "Company",
                collection: company,
            },
        ];

        return (tabsRequired)
            ? typeMap
            : typeMap.slice(0, 1);
    };

    /**
     * Get the tab content to display on the page
     *
     * @return {array} An array of tabs
     */
    getTabContent = () => {
        const availableDocs = this.formatAvailableTypes();

        return availableDocs.map((type) => {
            return {
                text: type.text,
            };
        });
    };

    /**
     * Gets the documents to show based on the filter set
     *
     * @return {array} An array of the documents to show
     */
    getDocumentsToShow = () => {
        const { tab, documents } = this.state;

        if (!documents.length) {
            return documents;
        }

        if (tab === null){
            return [];
        }

        return documents[tab].collection;
    };

    /**
     * Called when one of the filter tabs is clicked
     *
     * @param {number} index The index of the tab clicked
     *
     * @return {void}
     */
    handleTabChange = (index) => {
        this.setState({
            tab: null,
            shouldAnimate: false,
            value: "",
            documents: this.formatAvailableTypes(),
        }, () => this.setState({
            tab: index,
        }));
    };

    /**
     * Conditional render the tab content based on document collection
     *
     * @return {ReactElement} The filter tabs
     */
    renderTabs () {
        const { accentColour } = this.props.appState;
        const tabContent = this.getTabContent();

        if (tabContent.length <= 1) {
            return null;
        }

        return (
            <div className={styles.filterBar}>
                <FilterTab
                    defaultColor={"#696969"}
                    selectedColor={"#444444"}
                    tabContent={tabContent}
                    accentColour={accentColour}
                    onTabChange={this.handleTabChange}
                />
            </div>
        );
    }

    /**
     * This calls the search and sets the state for the documents page
     *
     * @param {Event} target The filter value
     * @return {func} The filtering of docs
     */
    handleSearch = ({ target }) => {
        const appstateDocuments = this.formatAvailableTypes();
        const searchValue = target.value.toLowerCase();

        if (!searchValue.length) {
            this.setFilteredDocuments(appstateDocuments, "");

            return null;
        }

        const filteredDocs = appstateDocuments.map((documentType) => {
            return {
                ...documentType,
                collection: documentType.collection.filter((userDocs) => {
                    const { description } = userDocs;
                    const comparisonValue = `${description}`.toLowerCase();

                    return comparisonValue.indexOf(searchValue) >= 0;
                }),
            };
        });

        this.setFilteredDocuments(filteredDocs, searchValue);

        return (this.props.appState.documents);
    };

    /**
     * Renders the page header
     *
     * @return {ReactComponent} the page header
     */
    renderPageHeader () {
        return (
            <AnimationContainer
                animationStyle="animationContainer"
                appearTimeout={200}
                enterTimeout={1000}
                exitTimeout={100}
                className={styles.animationContainer}
            >
                <PageHeader
                    title={this.pageName}
                    wrapperClass={styles.pageHeader}
                    headingClass={styles.heading}
                >
                    {this.renderTabs()}
                </PageHeader>
            </AnimationContainer>
        );
    }

    /**
     * Renders the documents page
     *
     * @return {ReactElement} The page
     */
    render () {
        const personalCompanyDocs = _.orderBy(this.getDocumentsToShow(), ['id'], ['desc']);
        const appearTimeout = personalCompanyDocs.length * 125;

        personalCompanyDocs.sort((a, b) => {
            return naturalCompare(b.datetime_added, a.datetime_added);
        });

        const { shouldAnimate, value } = this.state;

        return (
            <PageFrame>
                {this.renderPageHeader()}
                <div className={styles.searchBoxContainer}>
                    <AnimationContainer
                        animationStyle="animationContainerShowMore"
                        appearTimeout={appearTimeout}
                        enterTimeout={1000}
                        exitTimeout={100}
                    >
                        <SearchBox
                            value={value}
                            onChange={this.handleSearch}
                            className={styles.searchBox}
                            onHover={this.state.hover}
                        />
                    </AnimationContainer>
                </div>
                <DocumentsFrame
                    animationDelay={200}
                    documents={personalCompanyDocs}
                    shouldAnimate={shouldAnimate}
                />
            </PageFrame>
        );
    }

}

export default appState.attachState(Documents);
