import React, { useRef } from "react";
import { Route, Switch } from 'react-router-dom';
import { useSelector } from "react-redux";
import _ from "lodash";
import { getMostContrastingColour } from "@dataplan/react-components/dist/lib";
import PrivateRoute from "utils/PrivateRoute";
import RouterNavigationBlocking from "utils/RouterNavigationBlocking";
import appStateMethods from "state/App";
import { contrastingColourList } from 'lib/';
import { ACCENT_PRIMARY } from "./shared/Colours";

import {
    BackToTop,
    NotificationToast,
    SnackBars,
    TabNavigationFlags,
} from "@dataplan/react-components/dist/components";

import {
    AppHeader,
    PoweredByGraphic,
    SideMenu,
    SkipLinkMenu,
} from "components/";

import {
    AccountRecovery,
    Announcement,
    Authentication,
    Documents,
    Feedback,
    Forbidden,
    Forms,
    Help,
    Home,
    Login,
    Logout,
    Messages,
    MyAccount,
    MyDetails,
    NotFound,
    Notifications,
    P11ds,
    P45s,
    P60s,
    PayslipListController,
    Pensions,
    RegisterPage,
    Security,
    SetToken,
    Settings,
    SinglePayslip,
    Welcome,
    ePayslipsApp,
} from "pages/";

import "normalize.css/normalize.css";
import "./App.module.scss";

/**
 * Renders the app
 *
 * @return {ReactElement} The app
 */
const App = () => {
    const {
        brandColour = ACCENT_PRIMARY,
        documents,
        loaded,
        messagesEnabled,
        notifications,
        p45s,
        payslips,
        snackBars,
        ssoEnabled,
    } = useSelector(({ appState }) => appState);

    const { closeNotification, closeSnackBar } = appStateMethods;

    const {
        eP60,
        P11d,
        personal,
        personalcompany,
        company,
        pension,
        "drawing_board_documents": drawingBoardDocuments,
    } = documents;

    const personalCompanyDocs = [...personal, ...personalcompany, ...company, ...drawingBoardDocuments];
    const sideMenuButtonRef = useRef();

    return (
        <>
            <TabNavigationFlags />
            <RouterNavigationBlocking />
            {loaded && (
                <>
                    <SkipLinkMenu />
                    <AppHeader sideMenuButtonRef={sideMenuButtonRef} />
                    <SideMenu buttonRef={sideMenuButtonRef} />
                    <NotificationToast
                        notifications={notifications}
                        onClose={closeNotification}
                    />
                    <SnackBars
                        snackBars={snackBars}
                        onClose={closeSnackBar}
                    />
                    <BackToTop
                        backgroundColor={brandColour}
                        iconColor={getMostContrastingColour(brandColour, contrastingColourList)}
                    />
                    <PoweredByGraphic />
                </>
            )}
            <Switch>
                <PrivateRoute exact path="/" component={Home} />
                <PrivateRoute
                    path="/payslips"
                    component={PayslipListController}
                    conditional={!_.isEmpty(payslips)}
                />
                <PrivateRoute path="/p60s" component={P60s} conditional={!_.isEmpty(eP60)} />
                <PrivateRoute path="/p11ds" component={P11ds} conditional={!_.isEmpty(P11d)} />
                <PrivateRoute path="/p45s" component={P45s} conditional={!_.isEmpty(p45s)} />
                <PrivateRoute
                    path="/messages"
                    component={Messages}
                    conditional={messagesEnabled}
                />
                <PrivateRoute
                    path="/documents"
                    component={Documents}
                    conditional={!_.isEmpty(personalCompanyDocs)}
                />
                <PrivateRoute
                    path="/pensions"
                    component={Pensions}
                    conditional={!_.isEmpty(pension)}
                />
                <PrivateRoute path="/notifications" component={Notifications} />
                <PrivateRoute path="/announcement/:announcement_id" component={Announcement} />
                <PrivateRoute
                    path="/payslip/:payslip_id"
                    component={SinglePayslip}
                    conditional={!_.isEmpty(payslips)}
                />
                <PrivateRoute path="/forms" component={Forms} />
                <PrivateRoute path="/logout" component={Logout} />
                <PrivateRoute
                    path="/my-account"
                    component={MyAccount}
                    conditional={!ssoEnabled}
                />
                <PrivateRoute path="/my-details" component={MyDetails} />
                <PrivateRoute path="/security" component={Security} />
                <PrivateRoute path="/authentication" component={Authentication} />
                <PrivateRoute path="/settings" component={Settings} />
                <PrivateRoute path="/help" component={Help} />
                <PrivateRoute path="/feedback" component={Feedback} />
                <PrivateRoute path="/welcome" component={Welcome} />
                <PrivateRoute path="/app" component={ePayslipsApp} />

                <Route path="/login" component={Login} />
                <Route path="/account_recovery" component={AccountRecovery} />
                <Route path="/set_token" component={SetToken} />

                <Route path="/register" component={RegisterPage} />

                <Route path="/forbidden" component={Forbidden} />
                <Route component={NotFound} />
            </Switch>
        </>
    );
};

export default App;
