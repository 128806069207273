import React from 'react';
import appState from "state/App";
import PropTypes from "prop-types";
import _ from "lodash";

import { getAppName } from "lib/";
import { PageHeader, PageFrame } from 'components/';
import { AnimationContainer, SelectInput } from "@dataplan/react-components/dist/components";
import SearchBox from "@dataplan/react-components/dist/components/forms/SearchBox";
import { FilterTab } from '@dataplan/react-components/dist/components/ui/tabs';
import AccordionSection from './AccordionSection';
import AccordionVideoCard from './AccordionVideoCard';
import AccordionTextCard from './AccordionTextCard';

import ChangeMobileNumberVideo from "./HelpVideos/ChangeMobileNumber/ChangeMobileNumber.mp4";
import ChangePasswordVideo from "./HelpVideos/ChangePassword/ChangePassword.mp4";
import ChangeUsernameVideo from "./HelpVideos/ChangeUsername/ChangeUsername.mp4";
import DownloadPayslipVideo from "./HelpVideos/DownloadPayslip/DownloadPayslip.mp4";
import EmailPayslipVideo from './HelpVideos/EmailPayslip/EmailPayslip.mp4';
import LogOutVideo from "./HelpVideos/LogOut/LogOut.mp4";
import NotificationsVideo from "./HelpVideos/Notifications/Notifications.mp4";
import PayslipAdditionsVideo from "./HelpVideos/PayslipAdditions/PayslipAdditions.mp4";
import PayslipDeductionsVideo from "./HelpVideos/PayslipDeductions/PayslipDeductions.mp4";
import ReadAnnouncementsVideo from "./HelpVideos/ReadCompanyAnnouncements/ReadCompanyAnnouncements.mp4";
import SecurityQuestionsVideo from "./HelpVideos/ChangeSecurityQuestions/ChangeSecurityQuestions.mp4";
import SendMessageVideo from "./HelpVideos/SendEmployerMessage/SendEmployerMessage.mp4";
import TraditionalPayslipVideo from "./HelpVideos/TraditionalPrintViewPayslip/TraditionalPrintViewPayslip.mp4";
import UpdateEmailAddressVideo from "./HelpVideos/UpdateEmailAddress/UpdateEmailAddress.mp4";
import ViewDocumentVideo from "./HelpVideos/ViewDocument/ViewDocument.mp4";
import ViewMessageVideo from "./HelpVideos/ViewMessage/ViewMessage.mp4";
import ViewP60Video from "./HelpVideos/ViewP60/ViewP60.mp4";
import YearToDateVideo from "./HelpVideos/YearToDate/YearToDate.mp4";

import styles from './Help.module.scss';

const AccordionTextConfig = {
    "Payslips": [
        {
            title: "How to email a payslip",
            bodyContent: (
                <AccordionTextCard
                    text={
                        `If you wish to email one of your payslips you can do so by going to 'Payslips' in the
                        primary navigation, finding the payslip you need and selecting more details. Open up the
                        traditional print version by selecting the 'View Payslip' and then selecting 'Send'. From
                        here you need to add in the e-mail address you wish to send this payslip to and give
                        it a password so it is sent securely. Once complete, select send payslip. You will receive
                        confirmation once this has been sent.`
                    }
                />
            ),
            headerContent: <div className={styles.subTitle}> How do I email a payslip? </div>,
        },
        {
            title: "How to download",
            bodyContent: (
                <AccordionTextCard
                    text={
                        `If you're wanting to download one of your payslips, you can do so by going to 'Payslips'
                        in the primary navigation, finding the payslip you wish to download and select more
                        details. Open up the more traditional print version of the payslip by selecting 'View
                        Payslip' and in the top right corner select 'Download'.`
                    }
                />
            ),
            headerContent: <div className={styles.subTitle}> How do I download a payslip? </div>,
        },
        {
            title: "How to see payslip additions",
            bodyContent: (
                <AccordionTextCard
                    text={
                        `To see your gross additions on your payslips, go to 'Payslips' in the primary navigation,
                        go to the payslip you wish to view, select view more and then select the drop down under
                        'Gross'. Here you will see a breakdown for the different gross additions.`
                    }
                />
            ),
            headerContent: <div className={styles.subTitle}> How do I see my payslip additions? </div>,
        },
        {
            title: "How to see payslip deductions",
            bodyContent: (
                <AccordionTextCard
                    text={
                        `To see the breakdown of your deductions on your payslip, go to 'Payslips' in the primary
                        navigation, find the payslip you wish to view and select more details. Under 'Deductions'
                        click the drop down and here you will be presented with a breakdown of all the different
                        deductions being taken that month.`
                    }
                />
            ),
            headerContent: <div className={styles.subTitle}> How do I see my payslip deductions? </div>,
        },
        {
            title: "How do I see the traditional print view of a payslip?",
            bodyContent: (
                <AccordionTextCard
                    text={
                        `To see a traditional print view version of your payslip, go to 'Payslips' in the primary
                        navigation and find the payslip you want and select more details. Then in the top right
                        corner select 'View Payslip'.`
                    }
                />
            ),
            headerContent: (
                <div className={styles.subTitle}>  How do I see the traditional print view of a payslip? </div >
            ),
        },
        {
            title: "How do I view my year to date information?",
            bodyContent: (
                <AccordionTextCard
                    text={
                        `To view a breakdown of your year to date information, go to 'Payslips' in the primary
                        navigation, find the payslip you wish to view and select more details. On the year to date
                        drop down, click the arrow and you will be shown a breakdown of your year to date
                        information.`
                    }
                />
            ),
            headerContent: <div className={styles.subTitle}> How do I view my year to date information? </div >,
        },
    ],
    "Notifications": [
        {
            filter: "Misc",
            title: "Where do I find all my notifications?",
            bodyContent: (
                <AccordionTextCard
                    text={
                        `To find your notifications, select the bell icon in the top right corner. This will show you
                        your most recent notifications and to see all of them select show more. Once here you can
                        filter this by all, the ones you have read and the ones that are still unread.`
                    }
                />
            ),
            headerContent: <div className={styles.subTitle}> Where do I find all my notifications? </div>,
        },
    ],
    "Announcements": [
        {
            title: "How do I read my company announcements?",
            bodyContent: (
                <AccordionTextCard
                    text={
                        `You will be alerted if you have a company announcement by a new notification. To view these,
                        tap on the bell icon in the top right. This will show you a list of your
                        latest notifications. To see them all select show more. From here you can sort your
                        notifications by all, ones you have read and the ones that are still unread. To see the
                        full message simply select on the title and view the message.`
                    }
                />
            ),
            headerContent: <div className={styles.subTitle}> How do I read my company announcements? </div>,
        },
    ],
    "My Account": [
        {
            title: "How do I change my email address?",
            bodyContent: (
                <AccordionTextCard
                    text={
                        `If you wish to update your email address on your account, you can do so by going to
                        'My Account', going to email address and entering in the new email address. Then confirm
                        the new email address and entering your password. Once completed select save. You
                        will receive confirmation once successfully completed.`
                    }
                />
            ),
            headerContent: <div className={styles.subTitle}> How do I change my email address? </div>,
        },
        {
            title: "How do I change my mobile number?",
            bodyContent: (
                <AccordionTextCard
                    text={
                        `If you need to update the mobile number on your account, you can do so by going to 'My
                        Account', selecting mobile number and entering your new mobile number re-entering it to
                        confirm, enter your password and then select save. You will receive confirmation once
                        this has been successfully completed.`
                    }
                />
            ),
            headerContent: <div className={styles.subTitle}> How do I change my mobile number? </div>,
        },
        {
            title: "How do I change my security questions?",
            bodyContent: (
                <AccordionTextCard
                    text={
                        `If you need to update your security questions and answers, you can do so by going
                        to 'My Account' and then security questions. From here simply fill out the form
                        with question 1 and question 2 and the answers. Enter your password and select save.
                        You will receive confirmation once successfully completed.`
                    }
                />
            ),
            headerContent: <div className={styles.subTitle}> How do I change my security questions? </div>,
        },
        {
            title: "How do I change my username?",
            bodyContent: (
                <AccordionTextCard
                    text={
                        `When needing to update your username you can do so by going to 'My Account', then username.
                        Enter a new username and confirm your new username by entering it again. Enter your
                        password and select save. You will receive confirmation once this has been successfully
                        completed.`
                    }
                />
            ),
            headerContent: <div className={styles.subTitle}> How do I change my username? </div>,
        },
        {
            title: "How do I log out?",
            bodyContent: (
                <AccordionTextCard
                    text={
                        `If you wish to log out of your account, you can do so by going to your my account icon
                        in the top corner. This should show your initials. Click and select log out. If you
                        was to just close down your browser instead of this, this would also log you out.`
                    }
                />
            ),
            headerContent: <div className={styles.subTitle}> How do I log out? </div>,
        },
        {
            title: "How do I change my password?",
            bodyContent: (
                <AccordionTextCard
                    text={
                        `If you wish to log out of your account, you can do so by going to 'My Account' and password
                        along the top. Simply enter your current password and then enter in your new password and
                        confirm your new password. Once all of the checks are completed, select save. You will
                        receive confirmation once successfully completed.`
                    }
                />
            ),
            headerContent: <div className={styles.subTitle}> How do I change my password? </div>,
        },
    ],
    "Documents": [
        {
            filter: "Docs",
            title: "How do I view a document?",
            bodyContent: (
                <AccordionTextCard
                    text={
                        `To view your documents, go to documents in the primary navigation.
                        Here you will see all the documents available to you. To open a document select
                        'View', download the file and then it will be available for you to open.`
                    }
                />
            ),
            headerContent: <div className={styles.subTitle}> How do I view a document? </div>,
        },
        {
            filter: "Docs",
            title: "How do I view a p60?",
            bodyContent: (
                <AccordionTextCard
                    text={
                        `To view your P60s, go to P60s in the primary navigation.
                        Here you will see all of your available P60 documents. To open a one of the files select
                        'View'. This will first preview on screen for you and you can download the file by
                        selecting download.`
                    }
                />
            ),
            headerContent: <div className={styles.subTitle}> How do I view a P60? </div>,
        },
    ],
    "Messages": [
        {
            title: "How do I read a message from my employer?",
            bodyContent: (
                <AccordionTextCard
                    text={
                        `To view and read any messages, go to 'Messages' in the primary navigation. You'll be
                        presented with your inbox, a list of messages that have been sent to you. Tap on the title
                        of the message you would like to view. Once you have read the message you can either go
                        back to your inbox or reply to the message.`
                    }
                />
            ),
            headerContent: <div className={styles.subTitle}> How do I read a message from my employer? </div>,
        },
        {
            title: "How do I send my employer a message?",
            bodyContent: (
                <AccordionTextCard
                    text={
                        `To send a new message go to 'Messages' in the primary navigation. Here you will be presented
                        with your inbox. To create and send a new message, select the 'Add Message' icon. Give your
                        message a subject, type your message and select send. You will receive a message
                        confirmation to confirm message was sent successfully.`
                    }
                />
            ),
            headerContent: <div className={styles.subTitle}> How do I send my employer a message? </div>,
        },
    ],
};

const AccordionVideoConfig = {
    "Payslips": [
        {
            title: "How to Email a payslip",
            bodyContent: <AccordionVideoCard video={EmailPayslipVideo} title={"How to Email a payslip"} />,
            headerContent: <div className={styles.subTitle}> How do I email a payslip? </div>,
        },
        {
            title: "How to download a payslip",
            bodyContent: <AccordionVideoCard video={DownloadPayslipVideo} title={"How to download a payslip"} />,
            headerContent: <div className={styles.subTitle}> How do I download a payslip? </div>,
        },
        {
            title: "How to see payslip additions",
            bodyContent: <AccordionVideoCard video={PayslipAdditionsVideo} title={"How to see payslip additions"} />,
            headerContent: <div className={styles.subTitle}> How do I see my payslip additions? </div>,
        },
        {
            title: "How to see payslip deductions",
            bodyContent: <AccordionVideoCard video={PayslipDeductionsVideo} title={"How to see payslip deductions"} />,
            headerContent: <div className={styles.subTitle}> How do I see my payslip deductions? </div>,
        },
        {
            title: "How do I see the traditional print view of a payslip?",
            bodyContent: (
                <AccordionVideoCard
                    video={TraditionalPayslipVideo}
                    title={"How do I see the traditional print view of a payslip?"}
                />
            ),
            headerContent: (
                <div className={styles.subTitle}>  How do I see the traditional print view of a payslip? </div >
            ),
        },
        {
            title: "How do I view my year to date information?",
            bodyContent: (
                <AccordionVideoCard video={YearToDateVideo} title={"How do I view my year to date information?"} />
            ),
            headerContent: <div className={styles.subTitle}> How do I view my year to date information? </div >,
        },
    ],
    "Notifications": [
        {
            filter: "Misc",
            title: "Where do I find all my notifications?",
            bodyContent: (
                <AccordionVideoCard video={NotificationsVideo} title={"Where do I find all my notifications?"} />
            ),
            headerContent: <div className={styles.subTitle}> Where do I find all my notifications? </div>,
        },
    ],
    "Announcements": [
        {
            title: "How do I read my company announcements?",
            bodyContent: (
                <AccordionVideoCard video={ReadAnnouncementsVideo} title={"How do I read my company announcements?"} />
            ),
            headerContent: <div className={styles.subTitle}> How do I read my company announcements? </div>,
        },
    ],
    "My Account": [
        {
            title: "How do I change my email address?",
            bodyContent: (
                <AccordionVideoCard video={UpdateEmailAddressVideo} title={"How do I change my email address?"} />
            ),
            headerContent: <div className={styles.subTitle}> How do I change my email address? </div>,
        },
        {
            title: "How do I change my mobile number?",
            bodyContent: (
                <AccordionVideoCard video={ChangeMobileNumberVideo} title={"How do I change my mobile number?"} />
            ),
            headerContent: <div className={styles.subTitle}> How do I change my mobile number? </div>,
        },
        {
            title: "How do I change my security questions?",
            bodyContent: (
                <AccordionVideoCard video={SecurityQuestionsVideo} title={"How do I change my security questions?"} />
            ),
            headerContent: <div className={styles.subTitle}> How do I change my security questions? </div>,
        },
        {
            title: "How do I change my username?",
            bodyContent: <AccordionVideoCard video={ChangeUsernameVideo} title={"How do I change my username?"} />,
            headerContent: <div className={styles.subTitle}> How do I change my username? </div>,
        },
        {
            title: "How do I log out?",
            bodyContent: <AccordionVideoCard video={LogOutVideo} title={"How do I log out?"} />,
            headerContent: <div className={styles.subTitle}> How do I log out? </div>,
        },
        {
            title: "How do I change my password?",
            bodyContent: <AccordionVideoCard video={ChangePasswordVideo} title={"How do I change my password?"} />,
            headerContent: <div className={styles.subTitle}> How do I change my password? </div>,
        },
    ],
    "Documents": [
        {
            filter: "Docs",
            title: "How do I view a document?",
            bodyContent: <AccordionVideoCard video={ViewDocumentVideo} title={"How do I view a document?"} />,
            headerContent: <div className={styles.subTitle}> How do I view a document? </div>,
        },
        {
            filter: "Docs",
            title: "How do I view a p60?",
            bodyContent: <AccordionVideoCard video={ViewP60Video} title={"How do I view a p60?"} />,
            headerContent: <div className={styles.subTitle}> How do I view a P60? </div>,
        },
    ],
    "Messages": [
        {
            title: "How do I read a message from my employer?",
            bodyContent: (
                <AccordionVideoCard video={ViewMessageVideo} title={"How do I read a message from my employer?"} />
            ),
            headerContent: <div className={styles.subTitle}> How do I read a message from my employer? </div>,
        },
        {
            title: "How do I send my employer a message?",
            bodyContent: <AccordionVideoCard video={SendMessageVideo} title={"How do I send my employer a message?"} />,
            headerContent: <div className={styles.subTitle}> How do I send my employer a message? </div>,
        },
    ],
};

class Help extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
    };

    /**
     * Creates a new instance of the component
     *
     * @param {object} props Input props
     * @return {void}
     */
    constructor (props) {
        super(props);

        this.pageName = "Help Centre";
        const { documents, messagesEnabled } = this.props.appState;
        const messages = messagesEnabled ? "Messages" : "";
        const docs = !_.isEmpty(documents) ? "Docs" : "";
        const contentArray = ["All", "Payslips", "Announcements", "My Account", docs, messages, "Misc"];
        this.sections = contentArray.filter((item) => item);

        this.state = {
            helpFilter: "All",
            searchTerm: "",
            currentTab: 0,
        };
    }

    /**
     * Called just after the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount = () => {
        appState.setPageName(this.pageName);
        document.title = this.pageName;
    };

    /**
     * Called when the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount = () => {
        document.title = getAppName();
    };

    /**
     * Renders the selector
     *
     * @return {ReactElement} The selector
     */
    renderSelector () {
        const { helpFilter, searchTerm } = this.state;

        const handleOnChange = (e) => {
            const value = e.target.value;
            this.setState({ searchTerm: value.toLowerCase() });
        };

        return (
            <div className={styles.selectors}>
                <div className={styles.filter}>
                    <SelectInput
                        className={styles.groupSelect}
                        name="Help Selector"
                        aria-label="Help Selector"
                        title="Help Selector"
                        onChange={this.renderSections}
                        value={helpFilter}
                        altBackground={true}
                    >
                        {this.sections.map((value) => <option key={value} value={value}>{value}</option>)}
                    </SelectInput>
                </div>
                <SearchBox
                    value={searchTerm}
                    onChange={handleOnChange}
                    rounded={false}
                    size={40}
                />
            </div>
        );
    }

    /**
     * Renders the chosen selection
     *
     * @param {Event} event the event
     *
     * @return {void}
     */
    renderSections = (event) => {
        this.setState({
            helpFilter: event.target.value,
        });
    };

    /**
     * Returns true if the given filter is the state filter or the state filter is "All"
     *
     * @param {string} filterGroup the filter group name
     * @returns {boolean} true if the given filter group matches the state filter or All filter
     */
    isHelpInFilterGroup = (filterGroup) => {
        const { helpFilter } = this.state;
        return (helpFilter === filterGroup || helpFilter === "All");
    };

    /**
     * Filters the accordion config based on the search term filter
     *
     * @returns {object} new sections config
     */
    filterSections () {
        const { searchTerm, currentTab } = this.state;
        const { documents, messagesEnabled } = this.props.appState;

        const content = currentTab === 0 ? AccordionVideoConfig : AccordionTextConfig;

        let filteredSections = {};

        _.each(content, (config, key) => {
            if ((key === 'Messages' && !messagesEnabled)
                || (key === 'Documents' && _.isEmpty(documents))
            ) {
                return;
            }

            _.each(config, (section) => {
                const containsWord = _.includes(section?.title.toLowerCase(), searchTerm);
                const inFilter = this.isHelpInFilterGroup(section?.filter || key);

                if (containsWord && inFilter) {
                    const currentSections = filteredSections[key] || [];
                    filteredSections[key] = [...currentSections, section];
                }
            });
        });

        return filteredSections;
    }

    /**
     * Called when one of the filter tabs is clicked
     *
     * @param {number} index The index of the tab clicked
     *
     * @return {void}
     */
    handleTabChange = (index) => this.setState({ currentTab: index });

    /**
     * Conditional render the tab content based on document collection
     *
     * @return {ReactElement} The filter tabs
     */
    renderTabs () {
        const { accentColour } = this.props.appState;
        const tabContent = [{text: "Video"}, {text: "Text"}];

        if (tabContent.length <= 1) {
            return null;
        }

        return (
            <div className={styles.tabs}>
                <FilterTab
                    defaultColor="#696969"
                    selectedColor="#444444"
                    tabContent={tabContent}
                    accentColour={accentColour}
                    onTabChange={this.handleTabChange}
                />
            </div>
        );
    }

    /**
     * Gets array of accordion sections
     *
     * @returns {array} Accordion Sections to render
     */
    getHelpSections () {
        return _.map(this.filterSections(), (config, key) => {
            return (
                <AccordionSection
                    key={key}
                    heading={key}
                    accordionList={config}
                />
            );
        });
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render = () => {
        return (
            <PageFrame>
                <AnimationContainer
                    animationStyle="animationContainer"
                    appearTimeout={200}
                    enterTimeout={1000}
                    exitTimeout={100}
                >
                    <PageHeader className={styles.pageHeader} title={this.pageName} />
                    <div className={styles.headerContainer}>
                        {this.renderTabs()}
                        {this.renderSelector()}
                    </div>
                </AnimationContainer>
                <AnimationContainer
                    animationStyle="animationContainer"
                    appearTimeout={500}
                    enterTimeout={1200}
                    exitTimeout={200}
                >
                    <div className={styles.pageContent}>
                        {this.getHelpSections()}
                    </div>
                </AnimationContainer>
            </PageFrame>
        );
    };

}

export default appState.attachState(Help);
