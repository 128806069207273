import React from "react";
import PropTypes from "prop-types";
import { LoginForm } from "@dataplan/react-components/dist/components/ui/login_form";
import { noop } from "lodash";
import { attachStates } from "utils/ReduxUtils";
import { api, getTestLabel, handleLoginPhase1, handleLoginPhase2, getAppName } from "lib/";
import DeviceTokenManager from "../../lib/DeviceTokenManager";
import userState from "state/User";
import appState from "state/App";
import { PageFrame } from 'components/';
import { LoadingSpinner } from "@dataplan/react-components/dist/components/ui/loading_spinner";

import styles from "./Login.module.scss";

class Login extends React.Component {

    static propTypes = {
        userState: PropTypes.shape(userState.getPropTypes()).isRequired,
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        history: PropTypes.object.isRequired,
    };

    /**
     * Creates an instance of the login page
     *
     * @param {object} props Input props
     */
    constructor (props) {
        super(props);

        this.state = {
            loginStatus: null,
            loginMessage: "",
            validationMessage: "",
            loadingCompleted: false,
            forename: "",
            surname: "",
            mfaNeeded: false,
            smsNeeded: false,
        };
    }

    /**
     * Called when the component is added to the DOM
     *
     * @return {undefined}
     */
    componentDidMount () {
        this.setDocumentDetails();
        this.checkUserLoggedIn();
    }

    /**
     * Called when the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount () {
        document.title = getAppName();
    }

    /**
     * Sets the document title and meta description based on the platform in use
     *
     * @return {void}
     */
    setDocumentDetails = () => {
        const isTipology = window.ENV.PLATFORM === "tipology";

        if (isTipology) {
            this.pageName = 'Login to Tipology';
            this.description = "Login to Tipology";
        } else {
            this.description = [
                "Login to ePayslips.",
                "ePayslips provides powerful pay and employment information directly to employees.",
            ].join(" ");

            this.pageName = "Login to ePayslips. Your secure employee communication platform.";
        }

        document.title = this.pageName;
        document.querySelector('meta[name="description"]').setAttribute("content", this.description);
    };

    /**
     * Redirects to the app if the user is already logged in
     */
    checkUserLoggedIn = () => {
        const { loggedIn } = this.props.userState;

        if (loggedIn) {
            handleLoginPhase2();
        } else {
            this.setRedirectUrl();
        }
    };

    /**
     * Checks if there is a redirect URL in localstorage and sets it in state
     * Sets redirectURL as null if not present
     *
     * @return {undefined}
     */
    setRedirectUrl = () => {
        const redirectUrl = sessionStorage.getItem("redirectUrl");
        sessionStorage.removeItem("redirectUrl");

        this.setState({
            redirectUrl,
        }, () => {
            if (redirectUrl) {
                this.doRedirect(redirectUrl);
            }
        });
    };

    /**
     * Loads all initial app data before the redirect to the home
     *
     * @param {object} params A object of additional params
     *
     * @return {void}
     */
    loadAppData (params) {
        handleLoginPhase1(params)
            .then(() => {
                const { forename, surname } = this.props.userState;

                /* eslint-disable no-underscore-dangle */
                if (window._paq) {
                    window._paq.push(["setUserId", this.props.userState.username]);
                }
                /* eslint-enable */

                this.setState({
                    loginMessage: `Welcome ${forename} ${surname}`,
                    loadingCompleted: true,
                    forename,
                    surname,
                });
            });
    }

    /**
     * Sms and mfa
     *
     * @param {array} smsRequired if required
     * @param {array} totpRequired if required
     * @param {any} mfaMessage the message to check against
     * @param {any} smsMessage the message to check against
     * @return {void}
     */
    smsAndMFA (smsRequired, totpRequired, mfaMessage, smsMessage) {
        if (smsRequired.indexOf(smsMessage) >= 0) {
            this.setState({
                loginStatus: "failure",
                loginMessage: smsMessage,
                validationMessage: smsMessage,
                smsNeeded: true,
            });

            return;
        }

        if (totpRequired.indexOf(mfaMessage) >= 0) {
            this.setState({
                loginStatus: "failure",
                loginMessage: mfaMessage,
                validationMessage: mfaMessage,
                mfaNeeded: true,
            });
        }
    }

    /**
     * The extra steps
     *
     * @param {object} error the error
     * @param {array} smsRequired if required
     * @param {array} totpRequired if required
     * @return {void}
     */
    processExtraSteps (error, smsRequired, totpRequired) {
        const message = error?.response?.data?.message || "External server error";
        const mfaNeeded = message === totpRequired;
        const smsNeeded = message === smsRequired;

        this.setState({
            loginStatus: "failure",
            loginMessage: message,
            validationMessage: message,
            mfaNeeded,
            smsNeeded,
        });
    }

    /**
     * Handles the api call for login
     *
     * @param {object} loginData login data
     * @param {bool} isTrusted if trusted
     * @return {void}
     */
    processLoginApiCall (loginData, isTrusted) {
        const totpRequired = ["Totp code is incorrect", "Totp code is required for this account"];
        const smsRequired = ["SMS code login is required for this account"];

        api.post("/auth/password", loginData).then((response) => {
            if (response.data.incomplete) {
                this.smsAndMFA(smsRequired, totpRequired, response.data.incomplete, response.data.incomplete);
                return;
            }

            const token = response.data.token;

            sessionStorage.setItem("apiToken", token);

            this.setState({
                loginStatus: "success",
            }, () => this.loadAppData({ isTrusted }));
        }).catch((error) => {
            this.processExtraSteps(error, smsRequired, totpRequired);
        });
    }

    /**
     * Handles sms code login
     *
     * @param {object} loginData the logindata
     * @param {bool} isTrusted if trusted
     * @return {void}
     */
    handleSmsLogin = (loginData, isTrusted) => {
        const smsRequired = ["SMS code login is required for this account"];

        api.post("/auth/smscode", loginData).then((response) => {
            const token = response.data.token;

            sessionStorage.setItem("apiToken", token);

            this.setState({
                loginStatus: "success",
            }, () => this.loadAppData({ isTrusted }));
        }).catch((error) => {
            this.processExtraSteps(error, smsRequired);
        });
    };

    /**
     * Handles the login type login
     *
     * @param {object} loginData the login data
     * @param {string} smsCode The code to use for validation
     * @param {string} mfaCode The code to use for validation
     * @param {boolean} isTrusted User has marked it as a trusted device
     */
    handleLoginType (loginData, smsCode, mfaCode, isTrusted) {
        if (this.state.smsNeeded) {
            loginData.smscode = smsCode;
            this.handleSmsLogin(loginData, isTrusted);
            return;
        }

        if (this.state.mfaNeeded) {
            loginData.totp = mfaCode;
        }

        this.processLoginApiCall(loginData, isTrusted);
    }

    /**
     * Processes the login attempt
     *
     * @param {string} username The username to login as
     * @param {string} password The password to use for validation
     * @param {string} smsCode The code to use for validation
     * @param {string} mfaCode The code to use for validation
     * @param {boolean} isTrusted User has marked it as a trusted device
     *
     * @return {void}
     */
    processLoginAttempt (username, password, smsCode, mfaCode, isTrusted) {
        const deviceTokenHandler = new DeviceTokenManager();
        const deviceToken = deviceTokenHandler.getToken();

        const loginData = {
            type: "employee",
            username,
            password,
        };

        if (deviceToken) {
            // eslint-disable-next-line camelcase
            loginData.device_token = deviceToken;
        }

        this.handleLoginType(loginData, smsCode, mfaCode, isTrusted);
    }

    /**
     * Called when a login attempt is made.
     *
     * @param {string} username The username to login as
     * @param {string} password The password to use for validation
     * @param {string} smsCode The code to use for validation
     * @param {string} mfaCode The code to use for validation
     * @param {boolean} trustedDevice User has marked it as a trusted device
     *
     * @return {void}
     */
    handleLoginAttempt = (username, password, smsCode, mfaCode, trustedDevice) => {
        this.setState({
            loginStatus: null,
        }, () => {
            this.processLoginAttempt(username, password, smsCode, mfaCode, trustedDevice);
        });
    };

    /**
     * Does a redirect via react-router or by emulating clicking a link for external URLs
     *
     * @param {string} url The url to redirect to
     *
     * @return {void}
     */
    doRedirect = (url) => {
        const link = document.createElement("a");

        link.setAttribute("href", url);
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
    };

    /**
     * Handles the account help button click
     *
     * @param {boolean} isTipology the current environment
     *
     * @returns {function} The click event
     */
    handleAccountHelpClick = (isTipology) => {
        return isTipology ? this.props.history.push('/account_recovery') : noop;
    };

    /**
     * Handles register button click
     *
     * @param {boolean} isTipology the current environment
     *
     * @returns {function} The click event
     */
    handleRegisterClick = (isTipology) => {
        return isTipology ? this.props.history.push('/register') : noop;
    };

    /**
     * Renders the login page content
     *
     * @return {ReactElement} The page container
     */
    render () {
        const {
            forename = "",
            loadingCompleted,
            loginMessage,
            loginStatus,
            redirectUrl,
            surname = "",
            validationMessage,
            mfaNeeded,
            smsNeeded,
        } = this.state;

        // redirectUrl is only null if the app is confirmed not redirecting
        if (redirectUrl !== null) {
            return (
                <LoadingSpinner className={styles.loadingSpinner} label="Redirecting" />
            );
        }

        const isTipology = window.ENV.PLATFORM === "tipology";

        return (
            <div className={styles.container}>
                <div className={styles.loginForm} data-cy={getTestLabel('loginForm')}>
                    <PageFrame>
                        <LoginForm
                            backgroundColor="#eee"
                            onLoginAttempt={this.handleLoginAttempt}
                            onLoginCompleted={handleLoginPhase2}
                            onAccountRecoveryPress={() => this.handleAccountHelpClick(isTipology)}
                            onRegisterPress={() => this.handleRegisterClick(isTipology)}
                            loggedInUser={{
                                forename,
                                surname,
                            }}
                            mfaNeeded={mfaNeeded}
                            smsNeeded={smsNeeded}
                            loginStatus={loginStatus}
                            loginMessage={loginMessage}
                            validationMessage={validationMessage}
                            loadingCompleted={loadingCompleted}
                            isTipology={isTipology}
                            finalAvatarPosition={{
                                top: this.props.appState.profileMenuPosition.top || 0,
                                left: this.props.appState.profileMenuPosition.left || 0,
                            }}
                            finalAvatarColour={this.props.appState.brandColour}
                        />
                    </PageFrame>
                </div>
            </div>
        );
    }

}

export default attachStates([appState, userState], Login);
