import React from "react";
import PropTypes from "prop-types";
import appState from "state/App";

import CardsFrame from "components/CardsFrame";
import { defaultP45Types } from 'lib/prop-schemas/p45Schema';
import { DocumentCard } from "@dataplan/react-components/dist/components/ui/cards/document_card";
import P45 from "lib/downloaders/P45";

import styles from './P45sFrame.module.scss';

class P45sFrame extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        onClick: PropTypes.func,
        p45s: PropTypes.arrayOf(PropTypes.shape(defaultP45Types)),
    };

    static defaultProps = {
        onClick: null,
        p45s: [],
    };

    /**
     * Function to change personalCompany type to personal for card subheading
     *
     * @param {string} docType The current doc type
     *
     * @return {string} Returns the Doctype for the subheading
     */
    getTypeName (docType) {
        const findReply = {
            personalcompany: 'personal',
        };

        return findReply[docType] || docType;
    }

    /**
     * Helper function to get the current P60 files for the given user
     *
     * @return {array} Returns an array of DocumentCard components to be rendered
     */
    getP45s () {
        const { onClick, p45s } = this.props;
        const { accentColour } = this.props.appState;

        return p45s.map((file) => {
            let { description, type } = file;
            let p45Handler = new P45(file);

            let primaryLabel = {
                text: p45Handler.getDateTime,
            };
            let actions = [
                {
                    action: (onClick)
                        ? () => onClick({ id: file.id, description, url: p45Handler.fileApi })
                        : () => p45Handler.download(),
                    label: 'View',
                    title: `View ${description}`,
                    backgroundColor: accentColour,
                },
            ];

            return (
                <div key={file.id}>
                    <DocumentCard
                        primaryLabel={primaryLabel}
                        actions={actions}
                        headerTitle={description}
                        subHeading={type}
                        actionClasses={styles.cardActions}
                        titleClasses={styles.displayFullTitle}
                    />
                </div>
            );
        });
    }

    /**
     * Renders the page menu and header
     *
     * @return {ReactElement} The page container
     */
    render () {
        return (
            <CardsFrame className={styles.p45sContainer}>
                {this.getP45s()}
            </CardsFrame>
        );
    }

}

export default appState.attachState(P45sFrame);
