import React from "react";
import PropTypes from "prop-types";
import appState from "state/App";

import CardsFrame from "components/CardsFrame";
import Document from "lib/downloaders/Document";
import DrawingBoardDocument from "../lib/downloaders/DrawingBoardDocument";
import P60s from "../lib/downloaders/P60s";
import { AnimationContainer } from "@dataplan/react-components/dist/components/ui/animation";
import { DocumentCard } from "@dataplan/react-components/dist/components/ui/cards/document_card";
import { defaultDocTypes } from 'lib/prop-schemas/documentSchema';

import styles from './DocumentsFrame.module.scss';

class DocumentsFrame extends React.Component {

    static propTypes = {
        animationDelay: PropTypes.number,
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        documents: PropTypes.arrayOf(PropTypes.shape(defaultDocTypes)),
        onClick: PropTypes.func,
        shouldAnimate: PropTypes.bool,
    };

    static defaultProps = {
        animationDelay: 0,
        documents: [],
        onClick: null,
        shouldAnimate: true,
    };

    /**
     * Function to change personalCompany type to personal for card subheading
     *
     * @param {string} docType The current doc type
     *
     * @return {string} Returns the Doctype for the subheading
     */
    getTypeName (docType) {
        const findReply = {
            personalcompany: 'personal',
            "drawing_board": 'personal',
        };

        return findReply[docType] || docType;
    }

    /**
     * Function to determine the doc type to apply certain wrap styles to
     *
     * @param {string} docType The current doc type
     *
     * @return {string|null} Returns header style to be applied
     */
    getTitleClassName (docType) {
        if (['eP60', 'P11d'].indexOf(docType) >= 0) {
            return styles.displayFullTitle;
        }

        return null;
    }

    /**
     * Returns required animation timeout based on index of element in list
     * The first 3 (initial phase) appear in 200ms intervals
     * The others appear in 100ms intervals
     *
     * @param {number} index The index of element in list
     * @return {Array} The required animation timeout
     */
    getAnimationTimeout = (index) => {
        const { animationDelay } = this.props;

        const itemNum = index + 1;
        const endPhaseNum = 3;
        const withinInitialPhase = (itemNum <= endPhaseNum);

        const timeout = (withinInitialPhase)
            ? (itemNum * 200)
            : ((itemNum + endPhaseNum) * 100);

        return animationDelay + timeout;
    };

    /**
     * Handle user cancelling navigation
     */
    handleSnackClose = () => {
        const { currentSnackbarId } = this.props.appState;

        appState.closeSnackBar(currentSnackbarId);
    };

    /**
     * Render the snack bar to prompt the user
     *
     * @param {object} documentHandler Handles the downloading of the document
     */
    renderSnackBar = (documentHandler) => {
        appState.addSnackBar({
            type: 'warn',
            message: "This file may contain sensitive information. Because this file may contain personal"
                + " data about yourself, you should keep it secure and take precautions when storing, "
                + "sharing or uploading it to any other services.",
            onConfirm: documentHandler.download,
            confirmText: "Download",
            onCancel: this.handleSnackClose,
            cancelText: "Cancel",
            showCancelButton: false,
        });
    };

    /**
     * Handles the clicking of documents
     *
     * @param {object} file The file/document
     * @param {object} documentHandler Handles the downloading of the document
     *
     * @return {void} The function dependant on file type
     */
    handleDocClick = (file, documentHandler) => {
        if (file.type !== "company") {
            this.renderSnackBar(documentHandler);
            return;
        }

        documentHandler.download();
    };

    /**
     * Helper function to get the current P60 files for the given user
     *
     * @return {array} Returns an array of DocumentCard components to be rendered
     */
    getDocuments () {
        const { documents, onClick, shouldAnimate } = this.props;
        const { accentColour } = this.props.appState;

        const classMap = {
            "drawing_board": DrawingBoardDocument,
            "Default": Document,
            "eP60": P60s,
        };

        return documents.map((file, index) => {
            const { description, type } = file;

            const documentHandler = classMap[type] !== undefined
                ? new classMap[type](file)
                : new classMap.Default(file);

            const primaryLabel = {
                text: documentHandler.getDateTime,
            };

            const actions = [
                {
                    action: (onClick)
                        ? () => onClick({ id: file.id, description, url: documentHandler.fileApi })
                        : () => this.handleDocClick(file, documentHandler),
                    label: 'View',
                    title: `View ${description}`,
                    backgroundColor: accentColour,
                },
            ];

            const fileTypeDisplay = this.getTypeName(type);

            const cardContainer = (
                <DocumentCard
                    primaryLabel={primaryLabel}
                    actions={actions}
                    headerTitle={description}
                    subHeading={fileTypeDisplay}
                    actionClasses={styles.cardActions}
                    titleClasses={this.getTitleClassName(type)}
                />
            );

            const documentWrapper = (shouldAnimate)
                ? cardContainer
                : (<div>{cardContainer}</div>);

            return (
                <AnimationContainer
                    animationStyle="animationContainer"
                    appearTimeout={this.getAnimationTimeout(index)}
                    enterTimeout={1000}
                    exitTimeout={100}
                    key={file.id}
                    shouldAnimate={shouldAnimate}
                >
                    {documentWrapper}
                </AnimationContainer>
            );
        });
    }

    /**
     * Renders the page menu and header
     *
     * @return {ReactElement} The page container
     */
    render () {
        return (
            <CardsFrame className={styles.documentsContainer}>
                {this.getDocuments()}
            </CardsFrame>
        );
    }

}

export default appState.attachState(DocumentsFrame);
